import Router from 'next/router';
import React from 'react'
import { BodyLayout } from '@infotrack/infotrackgo.web.core/components';
import { HomeLayout } from '~/components/core/layouts/HomeLayout/HomeLayout';
import { HomeInfoPane } from '~/components/Home/components/HomeInfoPane';
import lostBackground from '~/public/background-images/header_error_background.webp'

export default function Custom404() {
    return (
        <HomeLayout
            title="404 Not Found | InfoTrackGO"
            description="The page you were after could not be found"
        >
          <BodyLayout
                leftContent={
                    <HomeInfoPane
                        title={`We're sorry, something went wrong`}
                        descriptionLine1={`Page not found. Please try again.`}
                        actionButtonText="Search again"
                        actionButtonOnClick={() => {
                            Router.push('/');
                        }}
                        backgroundImage={lostBackground}
                    />
                }
          />
        </HomeLayout>
    );
}
